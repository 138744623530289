import { VitalOxygenNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { Note2NodeSpec, Note2NodeSpecCreateArgs } from '../../node-specs/note2NodeSpecs';

class VitalOxygenNodeSpec implements Note2NodeSpec<VitalOxygenNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  create(args?: Note2NodeSpecCreateArgs<VitalOxygenNodeData>): VitalOxygenNodeData {
    const { attrs = { vitals_collection: null, vnioxygen: null } } = args || {};

    return {
      type: VisitNote2NodeName.VitalOxygen,
      attrs,
    };
  }

  isEmpty(nodeData: VitalOxygenNodeData): boolean {
    return !nodeData.attrs.vnioxygen?.description;
  }

  merge(_nodeData: VitalOxygenNodeData, _otherNodeData: VitalOxygenNodeData): false {
    return false;
  }
}

export const vitalOxygenNodeSpec = new VitalOxygenNodeSpec();
