import { VitalBPNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { Note2NodeSpec, Note2NodeSpecCreateArgs } from '../../node-specs/note2NodeSpecs';

class VitalBPNodeSpec implements Note2NodeSpec<VitalBPNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  create(args?: Note2NodeSpecCreateArgs<VitalBPNodeData>): VitalBPNodeData {
    const { attrs = { vitals_collection: null, vnibp: null } } = args || {};

    return {
      type: VisitNote2NodeName.VitalBP,
      attrs,
    };
  }

  isEmpty(nodeData: VitalBPNodeData): boolean {
    return !nodeData.attrs.vnibp?.description;
  }

  merge(_nodeData: VitalBPNodeData, _otherNodeData: VitalBPNodeData): false {
    return false;
  }
}

export const vitalBPNodeSpec = new VitalBPNodeSpec();
