import { VitalPainNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { Note2NodeSpec, Note2NodeSpecCreateArgs } from '../../node-specs/note2NodeSpecs';

class VitalPainNodeSpec implements Note2NodeSpec<VitalPainNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  create(args?: Note2NodeSpecCreateArgs<VitalPainNodeData>): VitalPainNodeData {
    const { attrs = { vitals_collection: null, vnipain: null } } = args || {};

    return {
      type: VisitNote2NodeName.VitalPain,
      attrs,
    };
  }

  isEmpty(nodeData: VitalPainNodeData): boolean {
    return !nodeData.attrs.vnipain?.description;
  }

  merge(_nodeData: VitalPainNodeData, _otherNodeData: VitalPainNodeData): false {
    return false;
  }
}

export const vitalPainNodeSpec = new VitalPainNodeSpec();
