import { VitalTemperatureNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { Note2NodeSpec, Note2NodeSpecCreateArgs } from '../../node-specs/note2NodeSpecs';

class VitalTemperatureNodeSpec implements Note2NodeSpec<VitalTemperatureNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  create(args?: Note2NodeSpecCreateArgs<VitalTemperatureNodeData>): VitalTemperatureNodeData {
    const { attrs = { vitals_collection: null, vnitemperature: null } } = args || {};

    return {
      type: VisitNote2NodeName.VitalTemperature,
      attrs,
    };
  }

  isEmpty(nodeData: VitalTemperatureNodeData): boolean {
    return !nodeData.attrs.vnitemperature?.description;
  }

  merge(_nodeData: VitalTemperatureNodeData, _otherNodeData: VitalTemperatureNodeData): false {
    return false;
  }
}

export const vitalTemperatureNodeSpec = new VitalTemperatureNodeSpec();
