import { VitalRRNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { Note2NodeSpec, Note2NodeSpecCreateArgs } from '../../node-specs/note2NodeSpecs';

class VitalRRNodeSpec implements Note2NodeSpec<VitalRRNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  create(args?: Note2NodeSpecCreateArgs<VitalRRNodeData>): VitalRRNodeData {
    const { attrs = { vitals_collection: null, vnirr: null } } = args || {};

    return {
      type: VisitNote2NodeName.VitalRR,
      attrs,
    };
  }

  isEmpty(nodeData: VitalRRNodeData): boolean {
    return !nodeData.attrs.vnirr?.description;
  }

  merge(_nodeData: VitalRRNodeData, _otherNodeData: VitalRRNodeData): false {
    return false;
  }
}

export const vitalRRNodeSpec = new VitalRRNodeSpec();
