import { VitalRRNodeAttributes, VitalRRNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { createAttributeHTMLHandlers, createCustomNode } from '../customNodeHelpers';
import VitalRRNode, { VitalRRNodeProps } from './VitalRRNode';

const VitalRRExtension = createCustomNode<VitalRRNodeProps, VitalRRNodeAttributes>(
  VisitNote2NodeName.VitalRR,
  VitalRRNode,
  {
    group: 'block',

    atom: true,

    addAttributes() {
      return {
        vnirr: {
          default: null,
          ...createAttributeHTMLHandlers('vnirr'),
        },
        vitals_collection: {
          default: null,
          ...createAttributeHTMLHandlers('vitals_collection'),
        },
      };
    },
  },
);

/**
 * Returns the node data for a new `VitalRRNode`.
 *
 * @deprecated Use `vitalRRNodeSpec.create` instead.
 */
export function createVitalRRNodeData(): VitalRRNodeData {
  return {
    type: VisitNote2NodeName.VitalRR,
    attrs: {
      vnirr: null,
      vitals_collection: null,
    },
  };
}

export default VitalRRExtension;
