import { VitalHeightNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { Note2NodeSpec, Note2NodeSpecCreateArgs } from '../../node-specs/note2NodeSpecs';

class VitalHeightNodeSpec implements Note2NodeSpec<VitalHeightNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  create(args?: Note2NodeSpecCreateArgs<VitalHeightNodeData>): VitalHeightNodeData {
    const { attrs = { vitals_collection: null, vniheight: null } } = args || {};

    return {
      type: VisitNote2NodeName.VitalHeight,
      attrs,
    };
  }

  isEmpty(nodeData: VitalHeightNodeData): boolean {
    return !nodeData.attrs.vniheight?.description;
  }

  merge(_nodeData: VitalHeightNodeData, _otherNodeData: VitalHeightNodeData): false {
    return false;
  }
}

export const vitalHeightNodeSpec = new VitalHeightNodeSpec();
