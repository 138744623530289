import { VitalWeightNodeAttributes, VitalWeightNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { createAttributeHTMLHandlers, createCustomNode } from '../customNodeHelpers';
import VitalWeightNode, { VitalWeightNodeProps } from './VitalWeightNode';

const VitalWeightExtension = createCustomNode<VitalWeightNodeProps, VitalWeightNodeAttributes>(
  VisitNote2NodeName.VitalWeight,
  VitalWeightNode,
  {
    group: 'block',

    atom: true,

    addAttributes() {
      return {
        vniweight: {
          default: null,
          ...createAttributeHTMLHandlers('vniweight'),
        },
        vitals_collection: {
          default: null,
          ...createAttributeHTMLHandlers('vitals_collection'),
        },
      };
    },
  },
);

/**
 * Returns the node data for a new `VitalWeightNode`
 *
 * @deprecated Use `vitalWeightNodeSpec.create` instead.
 */
export function createVitalWeightNodeData(): VitalWeightNodeData {
  return {
    type: VisitNote2NodeName.VitalWeight,
    attrs: {
      vniweight: null,
      vitals_collection: null,
    },
  };
}

export default VitalWeightExtension;
