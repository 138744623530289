import { VitalHRNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { Note2NodeSpec, Note2NodeSpecCreateArgs } from '../../node-specs/note2NodeSpecs';

class VitalHRNodeSpec implements Note2NodeSpec<VitalHRNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  create(args?: Note2NodeSpecCreateArgs<VitalHRNodeData>): VitalHRNodeData {
    const { attrs = { vitals_collection: null, vnihr: null } } = args || {};

    return {
      type: VisitNote2NodeName.VitalHR,
      attrs,
    };
  }

  isEmpty(nodeData: VitalHRNodeData): boolean {
    return !nodeData.attrs.vnihr?.description;
  }

  merge(_nodeData: VitalHRNodeData, _otherNodeData: VitalHRNodeData): false {
    return false;
  }
}

export const vitalHRNodeSpec = new VitalHRNodeSpec();
