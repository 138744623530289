import { VitalWeightNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { Note2NodeSpec, Note2NodeSpecCreateArgs } from '../../node-specs/note2NodeSpecs';

class VitalWeightNodeSpec implements Note2NodeSpec<VitalWeightNodeData> {
  defaultColumnIndex: 0 | 1 | null = 1;

  create(args?: Note2NodeSpecCreateArgs<VitalWeightNodeData>): VitalWeightNodeData {
    const { attrs = { vitals_collection: null, vniweight: null } } = args || {};

    return {
      type: VisitNote2NodeName.VitalWeight,
      attrs,
    };
  }

  isEmpty(nodeData: VitalWeightNodeData): boolean {
    return !nodeData.attrs.vniweight?.description;
  }

  merge(_nodeData: VitalWeightNodeData, _otherNodeData: VitalWeightNodeData): false {
    return false;
  }
}

export const vitalWeightNodeSpec = new VitalWeightNodeSpec();
