import { VitalPainNodeAttributes, VitalPainNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { createAttributeHTMLHandlers, createCustomNode } from '../customNodeHelpers';
import VitalPainNode, { VitalPainNodeProps } from './VitalPainNode';

const VitalPainExtension = createCustomNode<VitalPainNodeProps, VitalPainNodeAttributes>(
  VisitNote2NodeName.VitalPain,
  VitalPainNode,
  {
    group: 'block',

    atom: true,

    addAttributes() {
      return {
        vnipain: {
          default: null,
          ...createAttributeHTMLHandlers('vnipain'),
        },
        vitals_collection: {
          default: null,
          ...createAttributeHTMLHandlers('vitals_collection'),
        },
      };
    },
  },
);

/**
 * Returns the node data for a new `VitalPainNode`.
 *
 * @deprecated Use `vitalPainNodeSpec.create` instead.
 */
export function createVitalPainNodeData(): VitalPainNodeData {
  return {
    type: VisitNote2NodeName.VitalPain,
    attrs: {
      vnipain: null,
      vitals_collection: null,
    },
  };
}

export default VitalPainExtension;
