import { VitalOxygenNodeAttributes, VitalOxygenNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { createAttributeHTMLHandlers, createCustomNode } from '../customNodeHelpers';
import VitalOxygenNode, { VitalOxygenNodeProps } from './VitalOxygenNode';

const VitalOxygenExtension = createCustomNode<VitalOxygenNodeProps, VitalOxygenNodeAttributes>(
  VisitNote2NodeName.VitalOxygen,
  VitalOxygenNode,
  {
    group: 'block',

    atom: true,

    addAttributes() {
      return {
        vnioxygen: {
          default: null,
          ...createAttributeHTMLHandlers('vnioxygen'),
        },
        vitals_collection: {
          default: null,
          ...createAttributeHTMLHandlers('vitals_collection'),
        },
      };
    },
  },
);

/**
 * Returns the node data for a new `VitalOxygenNode`.
 *
 * @deprecated Use `vitalOxygenNodeSpec.create` instead.
 */
export function createVitalOxygenNodeData(): VitalOxygenNodeData {
  return {
    type: VisitNote2NodeName.VitalOxygen,
    attrs: {
      vnioxygen: null,
      vitals_collection: null,
    },
  };
}

export default VitalOxygenExtension;
