import { VitalBPNodeAttributes, VitalBPNodeData } from 'EntityTypes';
import { VisitNote2NodeName } from 'modules/visit-notes';

import { createAttributeHTMLHandlers, createCustomNode } from '../customNodeHelpers';
import VitalBPNode, { VitalBPNodeProps } from './VitalBPNode';

const VitalBPExtension = createCustomNode<VitalBPNodeProps, VitalBPNodeAttributes>(
  VisitNote2NodeName.VitalBP,
  VitalBPNode,
  {
    group: 'block',

    atom: true,

    addAttributes() {
      return {
        vnibp: {
          default: null,
          ...createAttributeHTMLHandlers('vnibp'),
        },
        vitals_collection: {
          default: null,
          ...createAttributeHTMLHandlers('vitals_collection'),
        },
      };
    },
  },
);

/**
 * Returns the node data for a new `VitalBPNode`.
 *
 * @deprecated Use `vitalBPNodeSpec.create` instead.
 */
export function createVitalBPNodeData(): VitalBPNodeData {
  return {
    type: VisitNote2NodeName.VitalBP,
    attrs: {
      vnibp: null,
      vitals_collection: null,
    },
  };
}

export default VitalBPExtension;
